<!--
 * @Author: 月魂
 * @Date: 2021-04-26 16:15:30
 * @LastEditTime: 2021-06-08 14:33:06
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \stoms-mp\src\views\Contract.vue
-->
<template>
  <div>
    <van-nav-bar
      title="中贝元儿童剧会员卡使用章程"
      fixed
      placeholder
    >
      <template #right>
        <van-icon name="wap-home-o" size="18" @click="jumpHome" />
      </template>
    </van-nav-bar>
    <div class="cont">
      <p>1. 概述</p>
      <p>1.1 本中贝元会员卡章程（以下简称 “本章程”）项下的会员卡（定义见下文）由发卡人（定义见下文）发行。会员卡可在中贝元儿童剧场（定义见下文）购买儿童剧演出门票或兑换指定产品。为规范会员卡的购买、使用和管理，特制定本章程。</p>
      <p>1.2 本章程涉及的部分名词遵从如下定义：</p>
      <p>“发卡人”指陕西中贝元儿童艺术剧院有限责任公司。</p>
      <p>“购卡人”指符合本章程之规定购买会员卡的单位或个人。</p>
      <p>“持卡人”指会员卡的实际持有人。</p>
      <p>“中贝元儿童剧场”指发卡人在其官方渠道上不时公布的和/或通过会员卡本身（卡面、券面或电子通知等方式）注明的、可受理会员卡的指定表演儿童剧的场所。</p>
      <p>“售卡企业”指经发卡人授权销售会员卡的机构，包括但不限于中贝元各个剧场、经发卡人书面授权的网络销售渠道等。发卡人将在其官网对取得其授权的售卡企业的名单进行公示。</p>
      <p>“特约商户”指中贝元儿童剧场，以及发卡人在其官网上不时公布的、可受理会员卡的其他中贝元品牌商户。</p>
      <p>1.3 发卡人、购卡人、持卡人、售卡企业、特约商户均应遵守本章程之规定，并受本章程之约束。</p>
      <p>2. 会员卡</p>
      <p>2.1 中贝元会员卡包括以下各种券卡：</p>
      <p>(1) 电子会员次卡： 以密码、串码、图形或其他电子形式作为载体的虚拟卡，可在中贝元各个儿童剧场观看儿童剧表演时的门票兑换，以购卡人购买的次数为本会员卡的有效使用次数。</p>
      <p>(2) 电子会员卡期卡： 以密码、串码、图形或其他电子形式作为载体的虚拟卡，可在中贝元各个儿童剧场观看儿童剧表演时用于门票兑换，以购卡人购买的开始日期和截止日期为本会员卡的有效使用日期。</p>
      <p>(3) 电子会员储值卡：以密码、串码、图形或其他电子形式作为载体的虚拟卡，具有充值与支付功能，可在中贝元各个儿童剧场观看儿童剧表演时用于门票购买与其他指定的商品购买。</p>
      <p>上述会员卡券在本章程中合称为“会员卡”。</p>
      <p>2.2 会员卡以人民币计价，面值不超过人民币1,000元。</p>
      <p>2.3 会员卡为不记名卡，不挂失、不用于兑换或提取现金、不具有透支功能。卡内资金不产生利息、红利或任何其他收益，且不可向银行账户或网络支付账户进行转账或转移。</p>
      <p>2.4 发卡人有权对会员卡的使用范围、方式或条件等作出补充规定并通过会员卡本身（例如卡面、券面或电子通知等方式）向购卡人/持卡人作出说明，购卡人及持卡人亦应遵守该等规定。</p>
      <p>3. 购买和充值</p>
      <p>3.1 满足本章程之购卡条件的单位或个人可以现金、借记卡、信用卡、银行转账或其他发卡人或售卡企业接受的支付方式，向发卡人或售卡企业购买会员卡。除前述销售渠道之外，发卡人未授权或委托任何机构、网站或个人以任何形式销售会员卡。</p>
      <p>3.2 持卡人可以现金、借记卡、信用卡或其他发卡人或售卡企业接受的支付方式，通过售卡企业、或发卡企业不时指定的其他渠道为会员卡进行充值。可充值的会员卡种类以及可办理充值的渠道与所在区域由发卡企业另行通知。</p>
      <p>3.3 单张实体会员卡/电子会员卡卡内累计余额不得超过1,000元。单次充值金额为人民币100元或100元的整数倍，或根据其他充值渠道及/或方式而定。</p>
      <p>3.5 实名认证</p>
      <p>3.5.1 如购卡人一次性购卡或持卡人一次性充值金额在1万元（含）以上的，应向发卡人、指定的售卡企业购买或致电发卡人客服电话由专人协助购买，并进行实名认证。</p>
      <p>3.5.2 为办理实名认证，购卡人/持卡人应提供有效的身份证件，包括但不限于购卡人/持卡人及其代理人姓名或单位名称、有效身份证件号码和联系方式，供发卡人或经其书面授权的售卡企业留存。个人有效身份证件包括居民身份证、户口簿、军人身份证件、武警身份证件、港澳台居民通行证、护照等。单位有效身份证件包括营业执照、事业单位法人证书、税务登记证、组织机构代码证等。</p>
      <p>3.5.3 发卡人及售卡企业应保存前述购卡人/持卡人的登记信息5年以上。</p>
      <p>3.6 单位一次性购卡金额达5000元（含）以上或个人一次性购卡或充值金额达5万元（含）以上的，以及单位或个人采用非现场方式购卡或充值的，不可使用现金，应通过银行向发卡人或售卡企业指定银行账户转账。购卡/充值单位或个人应提供银行付款账户的信息（包括：账户名称、账号及金额等）。</p>
      <p>3.7 应发卡人/售卡企业的要求，购卡人应与发卡人/售卡企业签订会员卡购买合同。若购卡人要求，发卡人/售卡企业亦应与购卡人签订会员卡购买合同。</p>
      <p>4. 使用</p>
      <p>4.1 在持卡人持会员卡在中贝元各个儿童剧场购买门票或兑换指定产品时，若会员卡余额不足的，持卡人可使用现金、信用卡或借记卡等特约商户可接受的支付方式补足。</p>
      <p>4.2 电子优惠券、特定产品电子券及电子会员卡无需激活，购买后即可使用。</p>
      <p>4.3 会员卡的有效期为使用之日起三年（或末次充值之日起三年，如适用），具体以会员卡卡面标识或以其他形式（包括但不限于电子通知的方式）告知的截止日期为准。持卡人应在有效期内使用会员卡。会员卡有效期届满后的处理规则如下：</p>
      <p>(1) 电子会员次卡：前述会员卡在有效期届满或者卡内购买的观剧次数为零后，持卡人可在线申请新的会员卡。</p>
      <p>2) 电子会员期卡：前述会员卡在有效期届满后，将自动延期30天，若在此次延期的到期日会员卡账户内仍有余额，将再自动延期30天，发卡人将于该第二次延期期限到期日的次月第一个工作日从该会员卡账户中扣除第二次延期的延期管理费；延期管理费按前一次延期到期日该会员卡账户余额的1%收取，但最低为人民币2元。之后的延期期限、延期方式和延期管理费的计算与收取以此类推。延期管理费将直接从该会员卡账户余额中扣取，直至扣完为止。</p>
      <p>（3）电子会员储值卡：对于卡内有余额的会员卡不设定过期期限卡内余额清零后，延期12个月没有使用过的卡，自动作废。</p>
      <p>4.5 会员卡交易以联网的方式自动化进行实时支付和结算；交易完成时，所购商品的对应金额即时从会员卡账户中扣除。交易中打印的销售小票是该项交易的有效凭据。</p>
      <p>4.6 除发卡人或特约商户另有规定外，使用会员卡时不可再享受商户的其他折扣或优惠。</p>
      <p>4.7 交易错误的查询与更正</p>
      <p>4.7.1 如发卡人在对账过程中发现交易中存在记账或其他交易差错，发卡人有权更正会员卡内余额。如对该等更正有疑义，持卡人可致电发卡人客服电话。发卡人将对持卡人的疑义进行调查，更正差错（如有）并及时告知持卡人处理结果。如不存在差错，发卡人将对持卡人作出相应解释。</p>
      <p>4.7.2 如持卡人认为交易过程中存在记账或其他交易差错，持卡人应在交易发生后30日内致电发卡人客服电话；经发卡人证实确有差错的，发卡人应更正会员卡内的余额，除此之外，发卡人不对持卡人承担任何其他责任。</p>
      <p>4.8 发卡人将向持卡人提供会员卡资金余额查询，向持卡人就会员卡使用的相关事项提供咨询，并处理持卡人的投诉。</p>
      <p>4.9 购卡人和/或持卡人不应将会员卡以任何形式转售予任何第三方，也不应将会员卡用于任何非法目的。</p>
      <p>4.10 会员卡仅供购卡人/持卡人在本章程规定的条件下使用，持有或使用会员卡不代表购卡人/持卡人获得授权使用“中贝元”、以及其他由发卡人或其关联公司拥有的标识、商标或任何其他与之相关的知识产权。</p>
      <p>4.11 因下述原因导致持卡人不能正常使用会员卡的，发卡人/特约商户/售卡企业对持卡人因此受到的损失不承担责任，但发卡人应当协助持卡人解决问题并提供必要的帮助：</p>
      <p>(1) 发卡人或儿童剧场运营或结算系统停机维修或维护；</p>
      <p>(2) 电力、电信或银行的系统、设备或网站发生故障、不稳定或升级维护，导致停电、或数据传输不稳定、失败或中断；</p>
      <p>(3) 台风、地震、海啸、洪水、战争、恐怖袭击、暴动、游行示威及其他不可抗力原因，造成发卡人或特约商户不能正常营业，或造成其运营或结算系统障碍不能正常运行。</p>
      <p>5. 退货、退卡、换卡与遗失</p>
      <p>5.1 退货</p>
      <p>5.1.1 除本章程第5.1.2款规定的情形外，若持卡人要求退掉持会员卡购买的商品且该等退货符合中贝元相关退货规定的，购买该商品时从该会员卡中支出的任何金额将被退至该会员卡内；原会员卡的有效期不因退货而改变。如原会员卡不存在，退货金额不足人民币100元（含）的，可支付现金；退货金额超过人民币100元的，就人民币100元整数倍的部分，发卡人将给予持卡人一张或多张新的会员卡，不足人民币100元的尾数部分，将支付现金。在此退货情形下，发卡人有权要求持卡人出示本章程第3.6款规定的身份证件。</p>
      <p>5.2 退卡</p>
      <p>5.2.1 若持卡人要求退卡的，退卡人应是购买会员卡的原购买人，并应在发卡人指定的渠道办理退卡。办理退卡时，个人退卡人应提供与原购卡人一致的有效身份证件，企业退卡人应提供加盖公章的退卡申请和营业执照复印件。会员卡退卡人应退还购卡的原始发票、充值发票（如适用）以及相关的付款凭证。如会员卡退卡人要求退还其在购买时享受过任何折扣或优惠的会员卡，在计算退款时将按退卡人在购买（及充值时，如适用）时实际支付的金额或按会员卡全额扣除上述优惠后的金额办理退款。如会员卡退卡人购买会员卡可按其年度累计购卡金额享有任何折扣或优惠的，在办理退卡后会将退卡金额从其年度累计购卡金额中扣除。</p>
      <p>5.2.2 退卡手续费统一按所退卡片余额2%的标准收取（如果退卡人所退卡片为两张或以上，则退卡手续费按照总余额2%的标准收取），最低人民币20元。在扣除前述手续费后，卡内剩余资金将被退至退卡人购买会员卡时使用的银行卡（在使用银行卡购买的情形下）或退卡人同名的银行账户内（在使用非银行卡交易形式购卡的情形下）。</p>
      <p>5.3 换卡</p>
      <p>5.3.1 对于实体会员卡，如因会员卡毁损无法交易或使用而需换卡的，经发卡人指定人员核实确为其所发会员卡且尚有余额，持卡人可在发卡人指定的场所办理更换，但应支付换卡手续费。实体会员卡的换卡手续费为每张每次人民币10元，直接从卡内余额中扣取或由持卡人支付现金。对于电子类会员卡，如购卡人未收到电子码或持卡人丢失电子码的，经发卡人核实该电子码尚未被使用，发卡人会将新电子码发送到原购卡人购卡时留存的手机号，并收取每次人民币1元的手续费，直接从卡内余额中扣取或由持卡人另行支付。在上述两种情形下，发卡人可要求持卡人出示本章程第3.6款规定的身份证件。</p>
      <p>5.4 自发卡人或售卡企业将会员卡交付给购卡人之时起（若为电子类会员卡，发卡人通过电子方式发送给购卡人即视为交付），会员卡的丢失、毁损等一切相关风险转移予购卡人。购卡人/持卡人未妥善保管会员卡导致会员卡污损、遗失、灭失的，应当自行承担损失。</p>
      <p>6. 发票</p>
      <p>6.1 就购卡人购买的会员卡或持卡人充值，发卡人将根据本章程约定的程序，向购卡人或持卡人开具符合国家适用法律、法规规定的发票。对于持卡人根据本章程第5.1.1项退货取得的新会员卡，发卡人不再开具发票。</p>
      <p>6.2 发票的申请与开具</p>
      <p>6.2.1 购卡人/持卡人在售卡企业处购买会员卡或充值后，应根据销售或充值小票上的销售单号和授权码，于购买会员卡或充值后30日内在发卡人官网上申请发票。</p>
      <p>6.2.2 购卡人在通过经发卡人书面授权的网络销售渠道购买会员卡或直接向发卡人购卡的、以及持卡人通过前述渠道充值时，应在购卡或充值的同时提出发票申请。</p>
      <p>6.2.3 购卡人/持卡人在申请发票时应提供有效的中国大陆的邮寄地址。发卡人将把发票以挂号信或快递形式发送至购卡人/持卡人提供的地址。由于地址有误或购卡人/持卡人不收/取件等非发卡人原因导致无法寄送的，购卡人/持卡人应自行承担损失。</p>
      <p>6.3 购卡人/持卡人所申请的发票金额需与销售或充值小票上的购卡金额一致；所申请的发票内容需符合国家适用法律、法规，不可依购卡人/持卡人任意的要求而开具。</p>
      <p>6.4 持卡人持会员卡在中贝元儿童剧场购买门票或兑换商品时，将不再就该商品开具发票；但同一笔交易中，若持卡人同时使用会员卡之外的付款方式进行付款，则可就会员卡之外的付款方式支付的款项根据国家适用法律、法规和特约商户的财务政策开具发票。</p>
      <p>7. 免责条款</p>
      <p>在适用法律允许的最大范围内，无论是基于侵权、违约还是其他诉由，发卡人、售卡企业或特约商户不对购卡人、持卡人或任何第三人因会员卡引起的任何直接或间接的损失或损害承担任何责任，包括但不限于任何收入损失、利润损失、商誉损失、数据损失、资金使用损失等。本条之规定不排除或限制发卡人、售卡企业在如下方面的责任：（1）欺诈行为引起的责任；（2）适用法律规定不应排除或限制的责任。</p>
      <p>8. 附则</p>
      <p>8.1 购卡人/持卡人存在以下情形的，发卡人有权拒绝交易、暂停或终止对购卡人/持卡人的服务，所有损失应由购卡人/持卡人自行承担：</p>
      <p>(1) 发卡人、售卡企业或特约商户根据国家适用法律、法规核实购卡人/持卡人身份及会员卡交易信息等情况，遭拒绝的；</p>
      <p>(2) 冒用他人身份或使用伪造、变造身份证明文件购买或使用会员卡或进行充值的；</p>
      <p>(3) 在发卡人公布的售卡或充值渠道之外购买会员卡或进行充值的；</p>
      <p>(4) 在购买或使用会员卡或进行充值时存在欺诈行为；</p>
      <p>(5) 违反本章程购买或使用会员卡或进行充值；</p>
      <p>(6) 违反国家或地方适用法律、法规进行非法交易；</p>
      <p>(7) 使用伪造、变造、残缺不全的会员卡；</p>
      <p>(8) 有其他损害发卡人、售卡企业、特约商户或其他购卡人/持卡人的合法权益的情形。</p>
      <p>8.2 若发卡人终止兑付未到期会员卡的，应向购卡人/持卡人提供免费退卡服务，并在终止兑付日前至少30日在会员卡备案机关指定的媒体上进行公示。</p>
      <p>8.3 本章程由发卡人制订，发卡人有权根据需要修改本章程，但将采取适当的形式在中贝元小剧场、发卡人官网或发卡人指定的其他途径提前公告。本章程经修改或调整公布后即生效，不再逐一通知购卡或持卡当事人。购卡人或持卡人有义务定期关注中贝元小剧场、发卡人官网或发卡人指定的其他途径以了解本章程的修改情况。若购卡人/持卡人在本章程修改之后仍继续购买和/或使用会员卡的，即视为当事人接受本章程的修改。如果购卡人/持卡人不同意修改，则应在本章程修改生效前将会员卡使用完毕，本章程生效之后应停止使用会员卡。</p>
      <p>8.4 争议解决</p>
      <p>8.4.1 任何由本章程引起的争议，双方应协商解决。若自一方书面通知另一方要求协商之日起15日内未能解决，则该等争议应交由上海仲裁委员会根据其当时有效的仲裁规则仲裁解决。仲裁庭应由一名仲裁员组成。仲裁应使用中文。仲裁裁决是终局的，对双方均具有法律约束力。</p>
      <p>8.4.2 在争议解决过程中，除正在进行仲裁的部分外，本章程的其他部分仍应继续执行。仲裁程序中的任何一方应对仲裁保密，不应将仲裁的内容、仲裁结果或仲裁本身披露给任何第三方，但法律要求披露的除外。</p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { NavBar, Icon } from 'vant';
Vue.use(NavBar).use(Icon);

export default {
  name: 'Contract',
  methods: {
    jumpHome() {
      this.$router.replace('/')
    },
  }
}
</script>

<style lang="less" scoped>
.cont {
  padding: 10px;
}
</style>
